import { MenuItem } from '@mui/material';
import React, { forwardRef } from 'react';

export interface SelectOptionProps {
    value: string|number;
    children: string;
    empty?: boolean;
    disabled?: boolean;
}

const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
    function SelectOption(
        { value, children, empty, disabled, ...otherProps },
        ref,
    ) {
        return (
            <MenuItem
                value={value}
                {...otherProps}
                ref={ref}
                disabled={disabled}
            >
                {
                    empty
                        ? <em>{children}</em>
                        : children
                }
            </MenuItem>
        );
    },
);

export default SelectOption;
